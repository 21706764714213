<template>
  <div>
    <!-- 导航栏-->
    <Nav nav="index" @scrollTop="getScrollTop"></Nav>
    <!--    菜单-->
    <sideMenu></sideMenu>
    <!--    banner-->

    <div class="block">
      <swiper ref="mySwiper">
        <swiper-slide class="banner-item">
          <img src="https://file.xssbdc.com/static/website/banner.png" alt=""/>
          <div class="banner-text">
            <span class="banner-text-title">科技金融 助力中小微企业</span>
            <span class="banner-text-title-en"
            >TECHNOLOGY FINANCE HELPS SMELL AND MICRO ENTERPRISES</span
            >
          </div>
        </swiper-slide>
        <swiper-slide>
          <img src="https://file.xssbdc.com/static/website/banner2.png" alt=""/>
          <div class="banner-text">
            <span class="banner-text-title">共享金融    合作发展</span>
            <span class="banner-text-title-en"
            >Sharing financial cooperation and development</span
            >
          </div>
        </swiper-slide>
        <swiper-slide>
          <img src="https://file.xssbdc.com/static/website/banner3.png" alt=""/>
          <div class="banner-text">
            <span class="banner-text-title">一切以客户需求为核心</span>
            <span class="banner-text-title-en"
            >All customer needs as the core</span
            >
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <!--    内容-->
    <div class="content">
      <!--      德润大数据-->
      <div class="de-bigData">
        <div class="title animate__animated">德润大数据</div>
        <div class="slogan animate__animated">
          打 造 领 先 的 大 数 据 金 融 平 台
        </div>
        <div class="info animate__animated">
          德润消费大数据(重庆)科技有限公司成立于2018年，公司专门针对互联金融场景用户、中小微企业、全民经纪人、贷款中介提供专属个性化定制的金融服务支持，
        </div>
        <div class="info animate__animated">
          并通过平台输出能力专注服务互联网金融行业。同时提供公司金融业务流转线上化、服务过程透明化，
        </div>
        <div class="info animate__animated">
          贷后监控适时化、资产处置快速化等全方位服务，以科技驱动推进金融行业创新发展。
        </div>
        <div class="container">
          <div class="count animate__animated row">
            <div class="count-item col-md-4 col-sm-12">
              <div>
                <span class="count-num counter">10</span>
                <span class="count-unit">万 <span class="jia">+</span></span>
              </div>
              <span>服务人员</span>
            </div>
            <div class="count-item col-md-4 col-sm-12">
              <div>
                <span class="count-num counter">30</span>
                <span class="count-unit">亿 <span class="jia">+</span></span>
              </div>
              <span>促成贷款金额</span>
            </div>
            <div class="count-item col-md-4 col-sm-12">
              <div>
                <span class="count-num counter">60</span>
                <span class="count-unit">家 <span class="jia">+</span></span>
              </div>
              <span>合作机构</span>
            </div>
          </div>
        </div>
      </div>
      <!--      企业信息-->
      <div class="enterprise-info">
        <div class="container" style="height: 100%">
          <div class="bd">
            <!--        企业愿景-->
            <div class="yuanjing">
              <div class="qy-title">企业愿景</div>
              <div class="xhx-line"></div>
              <div class="qy-text">
                为中小微企业提供一站式、全周期、综合化的普惠金融服务。
              </div>
              <div class="qy-text">
                产品的优势体现在对保障普惠大众的理解。然而，让用户在既享受科学的金融保障，同时享受与保障相关的延伸综合服务，
              </div>
              <div class="qy-text">
                使用户拥有金融极致体验，正是互联网金融业的心声。
              </div>
            </div>
            <!--        企业优势-->
            <div class="youshi">
              <div class="qy-title">企业优势</div>
              <div class="xhx-line"></div>
              <div class="qy-text">
                企业已拥有成熟的互联网金融服务云平台，包括经纪人大数据服务系统、全民经纪人进件平台、
              </div>
              <div class="qy-text">
                渠道录件系统、房屋评估系统，并已布局大数据分析平台基础架构搭建。公司随着场景客户的深度推进，
              </div>
              <div class="qy-text">
                已为下一步金融区块链与人工智能技术落地的应用做好铺垫。
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      公司业务-->
      <div class="gs-business">
        <div class="gs-titl">公司业务</div>
        <div class="gs-titl-en">Service Items</div>
        <div class="container">
          <div class="business-box row">
            <router-link :to="`/business?type=${1}`"  class="business-item col-md-3 col-sm-6 col-xs-12">
              <div class="surface">
                <img src="https://file.xssbdc.com/static/website/ajf.png" class="ajf" alt=""/>
                <div class="cp-name">担保安居分</div>
                <div class="cp-shuom">专注房屋二押</div>
              </div>
              <div class="reverse">
                <div class="lines">
                  100
                  <span class="cp-danwei">万</span>
                </div>
                <div class="cp-text">额度最高</div>
                <div class="cp-text-t">先息后本</div>
                <div class="cp-text">中途不归本</div>
              </div>
            </router-link>
            <router-link :to="`/business?type=${2}`" class="business-item col-md-3 col-sm-6 col-xs-12">
              <div class="surface">
                <img src="https://file.xssbdc.com/static/website/lyf.png" class="ajf" alt=""/>
                <div class="cp-name">乐业保</div>
                <div class="cp-shuom">快速解决资金需求</div>
              </div>
              <div class="reverse">
                <div class="lines">
                  90
                  <span class="cp-danwei">万</span>
                </div>
                <div class="cp-text">额度最高</div>
                <div class="cp-text-t">等额本息</div>
                <div class="cp-text">有房就能贷</div>
              </div>
            </router-link>
            <router-link :to="`/business?type=${3}`" class="business-item col-md-3 col-sm-6 col-xs-12">
              <div class="surface">
                <img src="https://file.xssbdc.com/static/website/zccz.png" class="ajf" alt=""/>
                <div class="cp-name">资产处置</div>
                <div class="cp-shuom">不良资产快速价值变现</div>
              </div>
              <div class="reverse">
                <div class="cp-text-t t-jz">价值提升</div>
                <div class="cp-text t-clss">股权类资产</div>
                <div class="cp-text t-clss">债权类资产</div>
                <div class="cp-text">实物类资产</div>
              </div>
            </router-link>
            <router-link :to="`/business?type=${4}`" class="business-item col-md-3 col-sm-6 col-xs-12">
              <div class="surface">
                <img src="https://file.xssbdc.com/static/website/fpf.png" class="ajf" alt=""/>
                <div class="cp-name">专享房源</div>
                <div class="cp-shuom">享受房源特价</div>
              </div>
              <div class="reverse">
                <div class="cp-text-t t-jz">特价专享</div>
                <div class="cp-text t-clss">法拍房</div>
                <div class="cp-text t-clss">内部房</div>
                <div class="cp-text">避暑房</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!--      服务区域-->
      <div class="container">
        <div class="service-area row">

          <div class="service-area-info col-md-5 col-sm-12">
            <div class="service-area-info-tit">服务区域</div>
            <div class="service-area-info-tit-en">Service Area</div>
            <div class="area">重庆 四川 贵州 西安</div>
          </div>
          <div class="ditu-bg col-md-7 col-sm-12">
            <img src="https://file.xssbdc.com/static/website/ditu.png" class="ditu" alt="">
            <div class="astigmatism"></div>
            <div class="astigmatism astigmatism2"></div>
            <div class="astigmatism astigmatism3"></div>
          </div>
        </div>
      </div>
      <!--    新闻资讯-->
      <newInfo></newInfo>
      <!--      脚标-->
      <tfooter></tfooter>
    </div>
  </div>
</template>

<script>
import Nav from "../components/nav";
import sideMenu from "../components/side-menu";
import newInfo from "../components/new-info";
import tfooter from "../components/footer";
import {addAnimationStyle} from '../js/AnimationStyle';

export default {
  name: "index",
  components: {Nav, sideMenu, newInfo, tfooter},
  data() {
    return {
      openAnimation: false, //开启动画
      scrollTop: 0, //滑动位置
      first: true, //是否第一次滑动到  控制动画的显示
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let docWz = document
            .getElementsByClassName("title")[0]
            .getClientRects(); //当前元素位置
        let winHeight = window.screen.height; //屏幕高

        if ((docWz[0].y - winHeight) <= 0) {
          //在屏幕内
          this.animatuonContent();
          // this.animatuonInfo();
        }
        console.log("元素：", docWz, winHeight, (docWz - winHeight));

      }, 1000);

      window.$(".counter").countUp();
      window.$(".business-item").hover(
          function () {
            window.$(this).children(".surface").css("display", "none");
            window.$(this).children(".reverse").css("display", "flex");
          },
          function () {
            window.$(this).children(".surface").css("display", "flex");
            window.$(this).children(".reverse").css("display", "none");
          }
      );
    });
  },
  methods: {
    getScrollTop(e) {
      // console.log('wz:', e)
      this.scrollTop = e;
      if (this.scrollTop > 300 && this.scrollTop < 1100) {
        this.animatuonContent();

      }
      if (this.scrollTop > 1000 && this.scrollTop < 1500) {
        this.animatuonInfo();
      }
    },

    //介绍内容动画
    animatuonContent() {
      addAnimationStyle('fadeInUp',"title", 2, 0, 0);
      addAnimationStyle('fadeInUp',"slogan", 2, 0.3, 0);
      addAnimationStyle('fadeInUp',"info", 2, 0.5, 0);
      addAnimationStyle('fadeInUp',"info", 2, 0.8, 1);
      addAnimationStyle('fadeInUp',"info", 2, 1, 2);
      addAnimationStyle('fadeInUp',"count", 2, 1.3, 0);
    },
    //企业信息动画
    animatuonInfo() {
      addAnimationStyle('fadeInUp',"yuanjing", 2, 0, 0);
      addAnimationStyle('fadeInUp',"youshi", 2, 1, 0);
    },
  },
};
</script>

<style scoped lang="less">
.block {
  // width: 100%;
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: 0;
  // height: 100%;
  // max-height: 1000px;
  position: sticky;
  top: 0;

  .banner-item {
    //position: relative;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  .banner-text {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //z-index: 99;

    .banner-text-title {
      font-size: 76px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      display: inline-block;
      margin-bottom: 65px;
    }

    .banner-text-title-en {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.content {
  background-color: #ffffff;
  position: relative;
  // top: 1100px;
  // left: 0;
  // right: 0;
  // z-index: 10;
  padding-top: 140px;
}

//dr大数据
.de-bigData {
  text-align: center;
  margin-bottom: 130px;

  .title {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 55px;
    opacity: 0;
  }

  .slogan {
    font-size: 28px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-bottom: 60px;
    opacity: 0;
  }

  .info {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
    opacity: 0;
  }

  .count {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-top: 80px;
    opacity: 0;

    .count-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      // margin: 0 110px;
      margin-bottom: 30px;

      .count-num {
        font-size: 60px;
        font-family: DIN;
        font-weight: bold;
        color: #5656e4;
      }

      .count-unit {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #5656e4;
        .jia{
          position: relative;
          top: -20px;
          font-size: 22px;
          color: #d81b09;
        }
      }

      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}

//企业信息
.enterprise-info {
  background: url("https://file.xssbdc.com/static/website/bg.png") no-repeat center;
  background-size: cover;
  padding: 50px 0;
  .bd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}

//企业愿景
.qy-title {
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 26px;
}

.xhx-line {
  width: 40px;
  height: 4px;
  background: #ffffff;
  margin-bottom: 50px;
}

.qy-text {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
}

.yuanjing {
  margin-bottom: 140px;
  opacity: 0;
}

.youshi {
  opacity: 0;
}

//公司业务
.gs-business {
  width: 100%;
  // height: 800px;
  background-image: url("https://file.xssbdc.com/static/website/ywbg.png");
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 100px;
  margin: 0;

  .gs-titl {
    font-size: 40px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
  }

  .gs-titl-en {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-bottom: 108px;
  }

  .business-box {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    a{
      text-decoration: none;
    }
  }

  .business-item {
    // width: 320px;
    height: 230px;
    // margin: 0 20px;
    margin-bottom: 10px;

    .surface {
      // width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #ffffff;

      .ajf {
        width: 67px;
        height: 67px;
        margin-bottom: 28px;
      }

      .cp-name {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: 16px;
      }

      .cp-shuom {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .reverse {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #5656e4;
      display: none;

      .lines {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 13px;

        .cp-danwei {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .cp-text {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }

      .cp-text-t {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: 39px 0 14px;
      }

      .t-jz {
        margin: 0 0 28px;
      }

      .t-clss {
        margin-bottom: 25px;
      }
    }
  }
}

//服务区域
.service-area {
  // width: 100%;
  // height: 960px;
  background-color: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  padding: 100px 0 150px;

  .service-area-info {
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    // margin-right: 254px;
    padding-top: 10%;

    .service-area-info-tit {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      margin-bottom: 33px;
    }

    .service-area-info-tit-en {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-bottom: 76px;
    }

    .area {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }

  .ditu-bg {
    // width: 936px;
    // height: 776px;
    // // background-image: url("https://file.xssbdc.com/static/website/ditu.png");
    position: relative;

    .ditu {
      width: 100%;
    }

    .astigmatism {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 68%;
      left: 57%;
      background-color: #e21616;
      border-radius: 50%;
      animation: flashRound 1.5s linear infinite;
    }

    .astigmatism2 {
      animation: flashRound 1.5s linear 1.5s infinite;
    }

    .astigmatism3 {
      animation: flashRound 1.5s linear 2s infinite;
    }
  }
}

@keyframes flashRound {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(10);
  }
}

</style>
<style>
:root {
  --animate-delay: 0.1s;
}

.el-carousel__container {
  /* max-height: 1000px !important;
  height:1000px; */
}

.el-carousel__indicators--horizontal {
  bottom: 100px !important;
}
</style>

